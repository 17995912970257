import React, { useState } from 'react'
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import image from "../../assets/images/courses/preschool.jpg";
import { Modal, Button } from 'react-bootstrap'
import HubspotContactForm from './hubspot/HubspotContactForm';

const CoursesPage = () => {
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <Layout pageTitle="KWEC | Course Details">
      <Navbar />
      <PageHeader title="Course Details" />
      <section className="course-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="course-details__content">
              <div className="course-details__top">
                <div className="course-details__top-left">
                  <h2 className="course-details__title">Preschool Needs</h2>
                </div>
                <div className="course-details__top-right">
                  <a className="course-one__category">
                    For schools
                  </a>
                </div>
              </div>
              <div className="course-one__image">
                <img src={image} alt=""  width={100}/>
              </div>
              <div className="tab-content course-details__tab-content ">
                <div
                  className="tab-pane show active  animated fadeInUp"
                  role="tabpanel"
                  id="overview"
                >
                  <p className="course-details__tab-text"> Looking for assistance to establish a new preschool or revamp your existing preschool? Your search ends here! Kids World Education Consultants is the one-stop shop for all your preschool needs. </p>
                  <br/>
                  <h3>
                    How it works?
                  </h3>
                  <ul>
                    <li>
                    You choose and finalize the premises. Our marketing assistance team will guide you to develop brand identity (name, logo, tagline, etc.), website design and marketing collaterals along with establishing social media presence. 
                    </li>
                    <li>
                    Owing to our associations with multiple vendors, we’ll help you design classrooms and procure preschool equipment that you’ll need for adopting activity-based teaching techniques. 
                   </li>
                    <li>
                    Our administrative assistance team will guide you in staff recruitment process, conduct of admission process, fees structuring and effective communication with parents with respect to admission related queries. 
                     </li>
                     <li>
                     Our academic team will arrange for teachers’ training sessions, parents’ orientation sessions, curriculum and daily timetable. To ensure smooth functioning of your preschool, mentoring and continued support, our team will visit your preschool twice in an academic year.   </li>
                  </ul>
                  For further assistance, we are just a phone-call away!
                  <br/>
                  The best part is that we operate on a no-franchise model… It is your school and your brand!
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 ">
            <div className="course-details__price">
              <p className="course-details__price-text">
                Talk to our executive and enroll now!{' '}
              </p>
              <Button
                onClick={handleShow}
                className="thm-btn course-details__price-btn"
                variant="default"
              >
                Enquire Now
              </Button>
            </div>
          </div>
          <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Enquiry Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HubspotContactForm/>
              </Modal.Body>
            </Modal>
        </div>
      </div>
    </section>
      <Footer />
    </Layout>
  );
};

export default CoursesPage;
